.layoutBox {
  margin: 0 0.2rem;
  border-radius: 0.16rem;
  padding-bottom: 10px;
}
.communityNew {
  font-family: PingFangSC-Regular;
}
.communityNew h3 {
  font-size: 0.32rem;
  font-weight: bold;
  color: #373737;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.communityNew h3 .more {
  width: 0.6rem;
  text-align: center;
}
.communityNew .bottomColStyleP {
  font-size: 0.24rem;
  margin-top: 0.2rem;
}
.communityNew .bottomSpanStyles {
  /* text-align: center; */
  color: #999999;
  font-size: 0.3rem;
}
.communityNew .styleBottomVol {
  padding-bottom: 0.45rem;
}
.communityNew .bodydiv1Col2Style {
  color: #878787;
  margin-top: 0.05rem;
}
.communityNew .bottom {
  background: #ffffff;
  padding: 0 0.3rem;
}
.communityNew .van3Style {
  padding: 0.3rem 0 0.4rem 0;
  border-radius: 0.16rem;
}
.communityNew .van4Style {
  margin-top: 0.35rem;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 0.3rem;
}
.communityNew .row3Style {
  margin-bottom: 0.4rem;
}
.communityNew .row3Style span,
.communityNew .row3Style p {
  line-height: 0.48rem;
  display: block;
  font-size: 0.24rem;
  text-align: center;
}
.communityNew .row3Style span {
  font-size: 0.36rem;
  font-weight: 550;
  color: #333333;
  height: 0.48rem;
}
.communityNew .row3Style:nth-last-child(2),
.communityNew .row3Style:nth-last-child(1) {
  margin-bottom: 0;
}
.communityNew .row3Style.color1 span,
.communityNew .row3Style.color1 p {
  border-top: 0.03rem solid #ff4b04;
}
.communityNew .row3Style.color2 span,
.communityNew .row3Style.color2 p {
  border-top: 0.03rem solid #ff9e04;
}
.communityNew .bodyDiv1 {
  background: #ffffff;
  padding: 0.2rem 0.2rem;
  margin-bottom: 0.2rem;
  border-radius: 0.16rem;
}
.communityNew .bodyDiv1 .top {
  border-bottom: 0.01rem solid #D3D9DE;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.communityNew .bodyDiv1 .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background-color: #F8D33A;
}
.communityNew .content {
  background: #ffffff;
  padding: 0 0.2rem;
  border-radius: 0.16rem;
  padding-top: 0.2rem;
}
.communityNew .content .top {
  border-bottom: 0.01rem solid #D3D9DE;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityNew .content .top .t_left {
  display: flex;
  align-items: center;
}
.communityNew .content .top .t_left .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background-color: #F8D33A;
}
.communityNew .content .top .t_right {
  color: #999999;
  font-size: 0.24rem;
}
.communityNew .content .top .t_right .icon_arrR {
  margin-left: 0.1rem;
  width: 0.2rem;
  height: 0.2rem;
  border-top: 0.04rem solid #999;
  border-right: 0.04rem solid #999;
}
.communityNew .content .content-member {
  border-radius: 0.12rem;
  background: #ffffff;
  padding: 20px 8px;
  text-align: center;
}
.communityNew .content .content-member .zhiTitle {
  font-size: 0.24rem;
}
.communityNew .content .content-member .zhiCent {
  font-size: 0.24rem;
  margin-bottom: 0.15rem;
  margin-top: 0.15rem;
  display: flex;
  align-items: center;
}
.communityNew .content .content-member .zhiCent .state img {
  width: 0.24rem;
  display: block;
  margin: 0 auto;
}
.communityInfo {
  margin: 0.2rem;
  background: #FFFFFF;
  border-radius: 0.16rem;
  position: relative;
  z-index: 2;
}
.communityInfo .list {
  padding: 0.4rem 0;
  margin: ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.communityInfo .list .item {
  width: 50%;
  color: #666666;
  font-size: 0.24rem;
  text-align: center;
}
.communityInfo .list .item .yen {
  color: #333333;
  font-size: 0.36rem;
  font-weight: bold;
  margin-bottom: 0.08rem;
}
.communityInfo .list .split {
  width: 1px;
  height: 0.6rem;
  background: #E7E7E7;
}
.communityNew h3 {
  margin-bottom: 0;
}
.mainFontLightColor {
  color: #666666 !important;
}
.mainFontColor1 {
  color: #666666 !important;
}
.mainFontLightColor2 {
  color: #333333 !important;
}
