















































































































































































	.layoutBox {
		// margin-top: -1.5rem;
		// padding-top: 1.5rem;
		margin: 0 0.2rem;
		border-radius: 0.16rem;
		padding-bottom: 10px;
	}
.communityNew {
	font-family: PingFangSC-Regular;
	h3 {
		font-size: 0.32rem;
		// font-family: PingFang SC;
		font-weight: bold;
		color: #373737;
		margin-bottom: .25rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.more {
			width: .6rem;
			text-align: center;
		}
	}

	.bottomColStyleP {
		font-size: 0.24rem;
		margin-top: 0.2rem;
	}

	.bottomSpanStyles {
		/* text-align: center; */
		color: #999999;
		font-size: 0.3rem;
	}

	.styleBottomVol {
		padding-bottom: 0.45rem;
	}

	.bodydiv1Col2Style {
		color: #878787;
		margin-top: 0.05rem;
	}

	.bottom {
		background: #ffffff;
		padding: 0 0.3rem;
	}

	.van3Style {
		// margin-top: 0.35rem;
		padding: 0.3rem 0 0.4rem 0;
		// background: linear-gradient(90deg, rgba(177, 205, 255, .3) 0%, rgba(215, 210, 255, .3) 100%);
		border-radius: 0.16rem;
		// margin-bottom: .5rem;
	}

	.van4Style {
		margin-top: 0.35rem;
		text-align: center;
		border-bottom: 1px solid #f5f5f5;
		padding-bottom: 0.3rem;
	}

	.row3Style {
		margin-bottom: 0.4rem;
		.value {
			
		}
		span,
		p {
			// padding-left: 0.6rem;
			// height: 0.48rem;
			line-height: 0.48rem;
			display: block;
			font-size: 0.24rem;
			text-align: center;
			// font-family: PingFang SC;
		}
		span {
			font-size: .36rem;
			font-weight: 550;
			color: #333333;
			// font-family: Bahnschrift;
			height: 0.48rem;
		}
		
		&:nth-last-child(2),
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}

	.row3Style.color1 {
		span,
		p {
			border-top: 0.03rem solid #ff4b04;
		}
	}

	.row3Style.color2 {
		span,
		p {
			border-top: 0.03rem solid #ff9e04;
		}
	}

	.bodyDiv1 {
		background: #ffffff;
		padding: 0.2rem 0.2rem;
		margin-bottom: 0.2rem;
		border-radius: 0.16rem;
		// padding-top: 0.4rem;
		.top {
			border-bottom: 0.01rem solid #D3D9DE;
			padding: 0.2rem 0;
			display: flex;
			align-items: center;
			.kuai {
				margin-right: 0.22rem;
				width: 5px;
				height: 0.26rem;
				background-color: #F8D33A;
			}
		}
	}

	.content {
		background: #ffffff;
		padding: 0 0.2rem;
		border-radius: 0.16rem;
		padding-top: 0.2rem;
		.top {
			border-bottom: 0.01rem solid #D3D9DE;
			padding: 0.2rem 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.t_left {
				display: flex;
				align-items: center;
				.kuai {
					margin-right: 0.22rem;
					width: 5px;
					height: 0.26rem;
					background-color: #F8D33A;
				}
			}
			.t_right {
				color: #999999;
				font-size: 0.24rem;
				.icon_arrR {
					margin-left: 0.1rem;
					width: .2rem;
					height: .2rem;
					border-top: .04rem solid #999;
					border-right: .04rem solid #999;
				}
			}
		}

		.content-member {
			border-radius: .12rem;
			background: #ffffff;
			padding: 20px 8px;
			text-align: center;
			.zhiTitle {
				font-size: 0.24rem;
			}

			.zhiCent {
				font-size: 0.24rem;
				margin-bottom: 0.15rem;
				margin-top: 0.15rem;

				display: flex;
				align-items: center;
				.state img {
					width: 0.24rem;
					display: block;
					margin: 0 auto;
				}

			}
			
			.left {
				// text-align: left;
			}
		}
	}
}

// ----------20200410[start]---------
.communityInfo {
	margin: 0.2rem;
	// padding: .6rem .2rem 0;
	// height: 1.72rem;
	background: #FFFFFF;
	// box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: 0.16rem;
	// margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	// margin-top: 1.08rem;
	.list {
		padding: 0.4rem 0;
		margin: ;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			width: 50%;
			color: #666666;
			font-size: .24rem;
			// width: 3rem;
			text-align: center;
			.yen {
				color: #333333;
				font-size: 0.36rem;
				font-weight: bold;
				// font-family: Bahnschrift;
				margin-bottom: 0.08rem;
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}
.communityNew h3 {
	margin-bottom: 0;
}
.mainFontLightColor {
	color: #666666 !important;
}
.mainFontColor1 {
	color: #666666 !important;
}
.mainFontLightColor2 {
	color: #333333 !important;
}
// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;